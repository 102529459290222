import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import '../styles/header.css'
import $ from 'jquery' // important: case sensitive.
import { GrClose } from "@react-icons/all-files/gr/GrClose";
import { GrLinkNext } from "@react-icons/all-files/gr/GrLinkNext";
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import cardfoto1 from "../images/steak-card.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/nacos-card.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/Bifemanteiga-card.jpg" // Tell webpack this JS file uses this image
import cardfoto4 from "../images/maminha2.png" // Tell webpack this JS file uses this image

import logo from "../images/logo2.png" // Tell webpack this JS file uses this image

import menu from "../images/menu.png" // Tell webpack this JS file uses this image
if (typeof window !== `undefined`) {

var desktopquery = window.matchMedia('(min-width: 992px)');
var mobilequery = window.matchMedia('(max-width: 991px)');
}

function clickMenu(){
  if (desktopquery.matches){

  $('#menu-section').animate({
    left: '50%'
});
  };
  if (mobilequery.matches){
    $('#menu-section').animate({
      left: '0'
  });
};
}

function closeMenu(){
  if (desktopquery.matches){

  $('#menu-section').animate({
    left: '100%'
});
  };
  if (mobilequery.matches){
    $('#menu-section').animate({
      left: '100%'
  });
};

}



const Header = () => {
  return (
    <div>
        <div id="menu-section">
            <div className="wrapper">
              <div className="header">
                <div className="title aller-display">
                As nossas sugestões
                </div>
                <div className="close-icon" onClick={closeMenu}>
                <GrClose/>
                </div>
                
              </div>
              <div className="conteudo">
              <Link to="/maminha/">
                <div className="receita1 card">
                  <div className="contentor">
                  <div className="foto">
                  <img src={cardfoto4} alt="Logo" />

                  </div>
                  <div className="elements">
                    <div className="titulo absara">
                      Maminha no Forno com Molho BBQ
                    </div>
                    <div className="desc sneak-regular">
                    O molho BBQ agridoce é a combinação perfeita de sabores que contrasta com a gordura da carne.
                    </div>
                   
                  </div>
                  <div className="icon">
                      <GrLinkNext />
                    </div>
                    </div>
              </div>
              </Link>
              <Link to="/nacos/">
              <div className="receita1 card">
                  <div className="contentor">
                  <div className="foto">
                  <img src={cardfoto2} alt="Logo" />

                  </div>
                  <div className="elements">
                    <div className="titulo absara">
                      Nacos com Batata Doce Assada na Grelha
                    </div>
                    <div className="desc sneak-regular">
                    Os nacos grelhados na brasa deixam evidenciar o puro sabor da carne.


                    </div>
                   
                  </div>
                  <div className="icon">
                      <GrLinkNext />
                    </div>
                    </div>
              </div>
              </Link>
              <Link to="/bife-manteiga/">
              <div className="receita1 card">
                  <div className="contentor">
                  <div className="foto">
                  <img src={cardfoto3} alt="Logo" />

                  </div>
                  <div className="elements">
                    <div className="titulo absara">
                      Bife com Manteiga e Alecrim com Batata
                    </div>
                    <div className="desc sneak-regular">
                    A carne tenra e macia, envolvida num molho cremoso, propicia-lhe uma experiência plena de sabor.</div>
                   
                  </div>
                  <div className="icon">
                      <GrLinkNext />
                    </div>
                    </div>
              </div>
              </Link>
              <Link to="/steak-sandwich/">
              <div className="receita1 card">
                  <div className="contentor">
                  <div className="foto">
                  <img src={cardfoto1} alt="Logo" />

                  </div>
                  <div className="elements">
                    <div className="titulo absara">
                      Steak Sandwich com Cebola e Cheddar
                    </div>
                    <div className="desc sneak-regular">
                    Uma deliciosa sanduíche artesanal, que é uma explosão de sabores a cada dentada.</div>
                   
                  </div>
                  <div className="icon">
                      <GrLinkNext />
                    </div>
                    </div>
              </div>
              </Link>
            
              </div>

          </div>
        </div>
      <Headroom >
      <div id="barra-nav">
        <div className="nav-container">
          <div className="brand">
            <Link to="/" title="Homepage"><img src={logo} alt="Logo" /></Link>
          </div>
          <div className="menu">
          <img onClick={clickMenu} src={menu} alt="Logo" />

          </div>
          
    
        
       
   
       
     
  
  </div></div>

    </Headroom>
  
    </div>
    
  )

}
export default Header