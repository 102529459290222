import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import logo from "../images/logo-black.png" // Tell webpack this JS file uses this image
import '../styles/footer.css'
import certis from "../images/certis.png" // Tell webpack this JS file uses this image
import logoblack from "../images/logo.png" // Tell webpack this JS file uses this image
import frescura from "../images/selo1.png" // Tell webpack this JS file uses this image
import frescura2 from "../images/selo2.png" // Tell webpack this JS file uses this image





const Footer = () => {
    return(
        <footer>
              <div id="footer-section" >
                  <div className="wrapper-container">
                    <div className ="left-col">
                        <div className="titulo sneak-bold">
                            <p>Navegação</p>
                        </div>
                        <div className="lista-links sneak-regular">
                        <Link to="/maminha/"> <a className="hover" ><span>Maminha no Forno com molho BBQ</span><br></br></a></Link>
                        <Link to="/steak-sandwich/"> <a className="hover" > <span>Steak Sandwich com Cebola e Cheddar</span><br></br></a></Link>
                        <Link to="/nacos/">  <a className="hover" ><span>Nacos com Batata Doce Assada na grelha</span><br></br></a></Link>
                        <Link to="/bife-manteiga/">  <a className="hover" ><span>Bife com manteiga e alecrim com batata</span><br></br></a></Link>


                        </div>

                    </div>
                    <div className ="center-col">
                    <div className="titulo sneak-bold">
                            <p>Comercialização</p>
                        </div>
                        <div className="lista-links sneak-regular">
                           <p>Vivid Foods © LDA. <br></br> Centro de Neg. VN Barquinha<br></br> Lote 47 <br></br> 2260-067 Atalaia<br></br>geral@vividfoods.com</p>
                        </div>
                    </div>
                    <div className ="center2-col">
                    <div className="titulo sneak-bold">
                            <p>Social</p>
                        </div>
                        <div className="lista-links sneak-regular">
                        <span>
                            Facebook
                        </span> <br></br>    
                        <span>
                            Instagram
                        </span>
                   </div>
                        </div>
                    <div className ="right-col">
                        <div className="certis-logo">
                            <img  src={certis} alt="" />
                        </div>
                        <div className="certis-logo">
                            <img  src={frescura} alt="" />
                        </div>
                        <div className="certis-logo">
                            <img  src={frescura2} alt="" />
                        </div>

                    </div>
                  </div>
                  <div className="wrapper-container-2">
                    <div className="col-left">
                        <div className="logo">
                        <img  src={logoblack} alt="" />

                        </div>
                    </div>
                    <div className="col-right">
                    <div className="desc">
                    <span className="sneak-bold">Joe´s Butcher © 2021</span><br></br>
                    <span className="sneak-regular">Handcrafted by <a className="link-40 sneak-bold" target="_blank" href="https://www.fortydegrees.pt">40º</a></span>

                    </div>
                    </div>
                  </div>


    
              </div>

        </footer>
    )


}

export default Footer